<template>
    <b-form-group :label-for="uuid" :invalid-feedback="errorMessage" class="font-weight-bold" label-size="sm">
        <template v-slot:label>
            {{ label }}
            <template v-if="!!tooltip">
                <b-icon :id="`show-tooltip-${uuid}`" class="bootstrap-icon" icon="question-circle-fill" />
                <tooltip :target="`show-tooltip-${uuid}`" :title="tooltip" />
            </template>
        </template>
        <datepicker
            :value="localValue"
            :name="uuid"
            format="M/d/yyyy"
            input-class="form-control"
            @input="onInput"
            :disabled-dates="disabledDates"
        ></datepicker>
        <b-form-invalid-feedback :force-show="hasError" class="form-control-error">
            {{ errorMessage }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { v4 } from 'uuid';
import Tooltip from '@/components/Common/Tooltip';
import moment from 'moment';
export default {
    name: 'DateInput',
    components: { Tooltip, Datepicker },
    props: {
        value: {
            type: [Date, String],
            required: false
        },
        label: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            required: false
        },
        errorMessage: {
            type: String,
            default: ''
        },
        disabledDates: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    computed: {
        hasError() {
            return !!this.errorMessage;
        },
        localValue() {
            if (this.value) {
                return moment(this.value, 'YYYY-MM-DD', true).toDate();
            }
            return undefined;
        }
    },
    data() {
        return {
            uuid: v4()
        };
    },
    methods: {
        onInput(value) {
            this.$emit(
                'input',
                moment(value)
                    .local()
                    .format('YYYY-MM-DD')
            );
        }
    }
};
</script>

<style scoped lang="scss">
/deep/ .form-control {
    border-radius: 0;

    &.is-invalid {
        border-color: #f04033;
        background-color: #ffe1e4;

        &:focus {
            background-color: #ffffff;
        }
    }
    &[readonly] {
        background: #ffffff;
    }
}
</style>
