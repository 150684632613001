<template>
    <div>
        <TextInput
            :model="model.description"
            :show-error="showErrors"
            :validator="v => !!v"
            name="other-detail"
            textarea
            title="Please provide a brief description"
            @inputChanged="val => (model.description = val)"
            @validate="val => this.$emit('validate', val)"
        />
        <div class="d-flex bottom">
            <span>{{ getCount }}/500</span>
            <span>Please limit your response to 500 characters.</span>
        </div>
    </div>
</template>

<script>
import TextInput from '@/components/FormGroups/TextInput';

export default {
    name: 'Other',
    components: { TextInput },
    props: {
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        getCount() {
            return this.model.description.length || 0;
        }
    }
};
</script>

<style lang="scss" scoped>
.bottom {
    flex-direction: row-reverse;

    span:first-child {
        margin-left: 60px;
    }
}
</style>
