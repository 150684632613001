<template>
    <div>
        <RadioButton
            :model="model.subType"
            :options="types"
            :show-error="showErrors"
            :validator="v => !!v"
            name="earrings-type"
            stacked
            @inputChanged="val => (model.subType = val)"
            @validate="val => this.$emit('validate', val)"
        />
    </div>
</template>

<script>
import RadioButton from '@/components/FormGroups/RadioButton';

export default {
    name: 'Earrings',
    components: { RadioButton },
    props: {
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        types: [
            { text: 'Pair of Earrings', value: '5' },
            { text: 'Single Earring', value: '6' }
        ]
    })
};
</script>

<style scoped></style>
