<template>
    <div>
        <div>
            <RadioButton
                :model="model.gender"
                :options="gender"
                :show-error="showErrors"
                :validator="v => !!v"
                name="watch-gender"
                @inputChanged="setGender"
            />
        </div>
        <div>
            <!--SINCE ONLY THIS PART HAS GROUPED RADIO BUTTONS, WE DID NOT CREATE A GLOBAL COMPONENT FOR THAT.-->
            <b-form-group>
                <b-form-radio-group
                    v-model="model.subType"
                    :state="showErrors && !model.subType ? false : null"
                    stacked
                    @change="setSubType"
                >
                    <b-form-radio value="14">Rolex</b-form-radio>
                    <b-col v-if="model.subType === '14'" class="pb-4 pl-4" cols="12" sm="10">
                        <TextInput
                            :model="model.serialNumber"
                            class="fixed-width"
                            name="rolex-serial-number"
                            title="Serial Number (optional)"
                            @inputChanged="setSerialNumber"
                        />
                        <span>
                            <b>Why provide the serial number?</b>
                            It's important to us to assist in replacing your jewelry item in the event of a loss.
                        </span>
                    </b-col>
                    <b-form-radio value="15">Smart Watch</b-form-radio>
                    <div v-if="model.subType === '15'" class="pb-4 pl-4">
                        <b-form-radio-group
                            v-model="model.brand"
                            :state="showErrors && !model.brand ? false : null"
                            @change="setBrand"
                        >
                            <b-form-radio value="1">Apple</b-form-radio>
                            <b-form-radio value="2">Other</b-form-radio>
                            <b-form-invalid-feedback :state="showErrors && !model.brand ? false : null">
                                The brand is required.
                            </b-form-invalid-feedback>
                        </b-form-radio-group>
                    </div>
                    <b-form-radio value="16">Other</b-form-radio>
                    <b-form-invalid-feedback :state="showErrors && !model.subType ? false : null">
                        This field is required.
                    </b-form-invalid-feedback>
                </b-form-radio-group>
            </b-form-group>
        </div>
    </div>
</template>

<script>
import RadioButton from '@/components/FormGroups/RadioButton';
import TextInput from '@/components/FormGroups/TextInput';

export default {
    name: 'Watch',
    components: { TextInput, RadioButton },
    props: {
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        gender: [
            { text: "Men's", value: 'Male' },
            { text: "Women's", value: 'Female' }
        ],
        serialNumber: '',
        brand: ''
    }),
    computed: {
        isAllValid() {
            return !!this.model.gender && !!this.model.subType && (this.model.subType !== '15' || !!this.model.brand);
        }
    },
    methods: {
        setGender(val) {
            this.model.gender = val;
            this.validate();
        },
        setSerialNumber(val) {
            this.model.serialNumber = val;
            this.serialNumber = val;
        },
        setBrand(val) {
            this.model.brand = val;
            this.brand = val;
            this.validate();
        },
        setSubType(val) {
            this.model.subType = val;
            this.model.serialNumber = null;
            this.model.brand = null;

            if (val === '14') {
                this.model.serialNumber = this.serialNumber;
            } else if (val === '15') {
                this.model.brand = this.brand;
            }
            this.validate();
        },
        validate() {
            this.$emit('validate', this.isAllValid);
        }
    }
};
</script>

<style scoped>
.fixed-width {
    width: 300px;
}
</style>
