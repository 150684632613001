<template>
    <div>
        <Title heading="Jewelry Details" />
        <validation-observer ref="form" tag="div">
            <b-form @submit.prevent.stop="submit">
                <Panel v-for="(item, i) in jewelryForm" :key="i" no-gutters>
                    <b-col class="text-lead" cols="12">
                        {{ i + 1 }}.
                        <b>{{ item.name }}</b>
                        <i class="ml-5 font-weight-light">
                            valued at
                            <DecimalPrice :price="item.value" />
                        </i>
                    </b-col>
                    <b-col class="mt-2 mb-4" cols="12">
                        <Ring
                            v-if="item.type === types.RING_TYPE"
                            :model="item"
                            :showErrors="showAllErrors"
                            @validate="val => $set(valid.jewelry, i, val)"
                        />
                        <Earrings
                            v-else-if="item.type === types.EARRINGS_TYPE"
                            :model="item"
                            :showErrors="showAllErrors"
                            @validate="val => $set(valid.jewelry, i, val)"
                        />
                        <Bracelet
                            v-else-if="item.type === types.BRACELET_TYPE"
                            :model="item"
                            :showErrors="showAllErrors"
                            @validate="val => $set(valid.jewelry, i, val)"
                        />
                        <Chain
                            v-else-if="item.type === types.CHAIN_TYPE"
                            :model="item"
                            :showErrors="showAllErrors"
                            @validate="val => $set(valid.jewelry, i, val)"
                        />
                        <Watch
                            v-else-if="item.type === types.WATCH_TYPE"
                            :model="item"
                            :showErrors="showAllErrors"
                            @validate="val => $set(valid.jewelry, i, val)"
                        />
                        <Other
                            v-else-if="item.type === types.OTHER_TYPE"
                            :model="item"
                            :showErrors="showAllErrors"
                            @validate="val => $set(valid.jewelry, i, val)"
                        />
                        <!--NECKLACE, PENDANT AND LOOSE STONE DO NOT HAVE A CONTENT FOR TYPES-->
                        <div v-else />
                    </b-col>
                    <b-col class="mt-3 date-of-purchase" cols="12">
                        <date-input
                            v-model="item.dateOfPurchase"
                            :disabled-dates="disableDatesAfterToday"
                            label="Date of Purchase or Last
        Appraisal (optional)"
                            tooltip="If you do not have your appraisal
        available, we will email you a link to upload your recent appraisal,
        evaluation or detailed receipt after you submit your online
        application."
                        ></date-input>
                    </b-col>
                    <!-- Uploading appraisal is not supported in MVP -- future feature
          <b-col class="mb-4" cols="12">
              <b>Upload a receipt or appraisal (optional)</b>
              <div class="mt-1">
                  <Button text="Select a File" type="button" />
              </div>
          </b-col>
          -->
                </Panel>
                <Panel class="pb-5">
                    <b-col class="text-lead font-weight-light" cols="12">
                        {{ jewelryForm.length + 1 }}. Alarm & Security Systems
                    </b-col>
                    <b-col cols="12">
                        <RadioButton
                            :model="applicationForm.security"
                            :options="securityOptions"
                            :show-error="showAllErrors"
                            :validator="v => !!v"
                            name="security-inputs"
                            title="Do you have an active alarm system in your home?"
                            @inputChanged="val => (applicationForm.security = val)"
                        />
                    </b-col>
                </Panel>
                <Panel class="pb-5">
                    <b-col class="text-lead font-weight-light" cols="12">
                        {{ jewelryForm.length + 2 }}. Select an Effective Date
                    </b-col>
                    <b-col cols="12">
                        <b-col cols="12" sm="6">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <date-input
                                    v-model="applicationForm.effectiveDate"
                                    label="When do you need coverage to begin?"
                                    :error-message="errors[0]"
                                    :disabled-dates="disableDatesForEffectivePolicy"
                                ></date-input>
                            </validation-provider>
                        </b-col>
                    </b-col>
                </Panel>
                <b-row no-gutters>
                    <b-col class="mobile-reverse" cols="12">
                        <div>
                            <span class="save" @click="saveAndFinishLater">Save & Finish Later</span>
                        </div>
                        <div>
                            <Button text="Next" type="submit" />
                        </div>
                    </b-col>
                    <b-col cols="12">
                        <div><span class="back" @click="back">&lt;&nbsp;Back</span></div>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <InvalidItemsModal :invalid-items="invalidItems" :all-items-invalid="allItemsInvalid" ref="invalidItemsModal" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import Title from '@/components/Common/Title';
import DecimalPrice from '@/components/Common/DecimalPrice';
import Button from '@/components/FormGroups/Button';
import Panel from '@/components/Common/Panel';
import Ring from '@/components/JewelryDetails/Ring';
import { TYPES, SECURITY_OPTIONS } from '@/constants/jewelryItems';
import Earrings from '@/components/JewelryDetails/Earrings';
import Bracelet from '@/components/JewelryDetails/Bracelet';
import Chain from '@/components/JewelryDetails/Chain';
import Other from '@/components/JewelryDetails/Other';
import RadioButton from '@/components/FormGroups/RadioButton';
import Watch from '@/components/JewelryDetails/Watch';
import DateInput from '@/components/FormGroups/DateInput';
import InvalidItemsModal from '@/components/Common/InvalidItemsModal';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { scrollToError } from '@/utils';
import jewelryValueRules from '@/utils/jewelry-value-rules';
import axios from 'axios';
import { isValidateLoss, isCrimeValidated } from '@/utils/jewelry-value-rules';

const jewelryTypesWithOptions = [
    TYPES.RING_TYPE,
    TYPES.EARRINGS_TYPE,
    TYPES.BRACELET_TYPE,
    TYPES.CHAIN_TYPE,
    TYPES.WATCH_TYPE,
    TYPES.OTHER_TYPE
];

export default {
    name: 'JewelryDetails',
    components: {
        Watch,
        RadioButton,
        Other,
        Chain,
        Bracelet,
        Earrings,
        Ring,
        Panel,
        DecimalPrice,
        Title,
        Button,
        DateInput,
        InvalidItemsModal,
        ValidationProvider,
        ValidationObserver
    },
    data: () => ({
        types: TYPES,
        jewelryForm: [],
        applicationForm: {
            security: ''
        },
        valid: {
            jewelry: []
        },
        showAllErrors: false,
        securityOptions: SECURITY_OPTIONS,
        disableDatesForEffectivePolicy: {
            to: moment().toDate(),
            from: moment()
                .add(60, 'days')
                .toDate()
        },
        disableDatesAfterToday: {
            from: moment().toDate()
        },
        invalidItems: [],
        allItemsInvalid: false
    }),
    computed: {
        ...mapGetters(['jewelryItems', 'getApplicant', 'applicantAndWearers']),
        isAllValid() {
            return !!this.applicationForm.security && this.valid.jewelry.findIndex(v => !v) === -1;
        }
    },
    mounted() {
        if (this.getApplicant && this.getApplicant.effectiveDate) {
            this.disableDatesForEffectivePolicy = {
                to: moment(this.getApplicant.effectiveDate).toDate(),
                from: moment(this.getApplicant.effectiveDate)
                    .add(60, 'days')
                    .toDate()
            };
        }
        if (
            !this.getApplicant ||
            !this.getApplicant.gender ||
            !isValidateLoss(this.applicantAndWearers) ||
            !isCrimeValidated(this.applicantAndWearers)
        ) {
            this.$router.replace('/applicant');
            return;
        }
        window.scrollTo(0, 0);

        this.applicationForm = {
            effectiveDate: this.getApplicant.effectiveDate || moment().format('YYYY-MM-DD'),
            security: this.getApplicant.security
        };

        this.jewelryForm = this.jewelryItems;
        this.valid.jewelry = this.jewelryItems.map(item => !jewelryTypesWithOptions.includes(item.type));
    },
    watch: {
        jewelryForm: {
            deep: true,
            handler: function(val) {
                this.setJewelryItems(val);
            }
        },
        applicationForm: {
            deep: true,
            handler: function(val) {
                this.setApplicant({
                    ...this.getApplicant,
                    ...val
                });
            }
        }
    },
    methods: {
        ...mapActions(['setJewelryItems']),
        ...mapMutations(['setApplicant']),
        async submit() {
            const isValid = this.$refs.form.validate();
            if (!isValid || !this.isAllValid) {
                this.showAllErrors = true;
                this.$nextTick(() => scrollToError(this.$refs.form));
                return;
            }

            const underwritingRulesValid = jewelryValueRules(this.jewelryForm, this.getApplicant.country);
            this.invalidItems = underwritingRulesValid.invalidItems;
            this.allItemsInvalid = underwritingRulesValid.allItemsInvalid;
            if (underwritingRulesValid.allItemsInvalid || underwritingRulesValid.invalidItems.length) {
                this.invalidItems.map(async item => {
                    const payload = {
                        mode: 'uw_limit_met',
                        eventCategory: 'Underwriting Rules',
                        eventAction: 'Item value limit hit',
                        eventLabel: 'Jewelry Details Page',
                        eventValue: item.value,
                        eventJewelryType: item.name
                    };

                    await axios.post(`/api/session/segment`, payload);
                });
                this.$refs.invalidItemsModal.show();
                return;
            }

            this.$router.push('/review');
        },
        back() {
            this.$router.push('/applicant');
        },
        saveAndFinishLater() {
            this.$eventBus.$emit('save');
        }
    }
};
</script>

<style lang="scss" scoped>
.date-of-purchase {
    /deep/ input {
        width: 200px;
    }
}

.mobile-reverse {
    display: flex;
    justify-content: space-between;

    @media (max-width: 599px) {
        flex-direction: column-reverse;
        text-align: center;

        button {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

.save {
    display: inline-block;
    color: $blue;
    font-weight: 700;
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
}

.back {
    color: $blue;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}
</style>
