<template>
    <div>
        <div>
            <RadioButton
                :model="model.gender"
                :options="gender"
                :show-error="showErrors"
                :validator="v => !!v"
                name="ring-gender"
                @inputChanged="val => (model.gender = val)"
                @validate="validate"
            />
        </div>
        <div>
            <RadioButton
                :model="model.subType"
                :options="types"
                :show-error="showErrors"
                :validator="v => !!v"
                name="ring-type"
                stacked
                @inputChanged="val => (model.subType = val)"
                @validate="validate"
            />
        </div>
    </div>
</template>

<script>
import RadioButton from '@/components/FormGroups/RadioButton';

export default {
    name: 'Ring',
    components: { RadioButton },
    props: {
        model: {
            type: Object,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        gender: [
            { text: "Men's", value: 'Male' },
            { text: "Women's", value: 'Female' }
        ],
        types: [
            { text: 'Engagement Ring', value: '1' },
            { text: 'Wedding Set', value: '2' },
            { text: 'Wedding Ring', value: '3' },
            { text: 'Other', value: '4' }
        ]
    }),
    methods: {
        validate() {
            this.$emit('validate', !!this.model.subType && !!this.model.gender);
        }
    }
};
</script>

<style scoped></style>
